import { defineStore } from "pinia";
import { ProjectModelType, ProjectItemType } from "@/types/ProjectType";
import { setFormData, setQueryParams } from "@/assets/js/functions";
import { ApiResponseSingleType, ApiResponseType } from "@/types/BasicType";

export const useProjectStore = defineStore("project", {
  state: () => ({
    projectsList: [] as ProjectItemType[],
    urlDeleteQueue: [] as (() => Promise<void>)[],
    isLoading: true,
    isNotFound: false,
  }),
  getters: {},
  actions: {
    async fetchProjectsList(params: object | null = null): Promise<void> {
      this.projectsList = [];
      let url = process.env.VUE_APP_BASE_API_URL + "/admin/project/list";
      if (params) {
        url += setQueryParams<typeof params>(params);
      }
      await fetch(url)
        .then(async (r) => {
          if (r.ok) {
            const json = await r.json();
            return json.data;
          } else if (r.status === 401) {
            location.href = "/login";
          } else {
            throw new Error(r.statusText);
          }
        })
        .then((data: ApiResponseType<ProjectItemType>) => {
          if (data.records?.length) {
            this.isNotFound = false;
            this.projectsList = data.records;
          } else {
            this.projectsList = [];
            this.isNotFound = true;
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async addNewProject(body: ProjectModelType) {
      const formData = new FormData();
      setFormData(formData, body);
      return await fetch(
        `${process.env.VUE_APP_BASE_API_URL}/admin/project/add`,
        {
          method: "POST",
          body: formData,
        }
      )
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else if (r.status === 401) {
            location.href = "/login";
          } else {
            throw new Error(r.statusText);
          }
        })
        .then((data: ApiResponseSingleType<ProjectItemType>) => {
          if (data.data) {
            this.projectsList.unshift(data.data);
            this.isNotFound = false;
          }
          return data;
        });
    },
    async editProject(guid: string, body: ProjectModelType) {
      if (
        body.Urls.findIndex((url) => url.Url.length < 3) === -1 &&
        body.Name.length >= 3
      ) {
        const formData = new FormData();
        setFormData(formData, body);
        return await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/admin/project/update/${guid}`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((r) => {
            if (r.ok) {
              return r.json();
            } else if (r.status === 401) {
              location.href = "/login";
            } else {
              throw new Error(r.statusText);
            }
          })
          .then((resp: ApiResponseSingleType<ProjectItemType>) => {
            if (resp.status === "OK") {
              const { data } = resp;
              const editedProject = this.projectsList.find(
                (item) => item.ID === guid
              );
              if (typeof editedProject !== "undefined") {
                editedProject.Name = data.Name;
                editedProject.UpdateAt = data.UpdateAt;
                editedProject.Urls = data.Urls;
                editedProject.IsActive = data.IsActive;
              }
            }
            return resp;
          });
      } else {
        throw new Error(
          JSON.stringify({
            Name: body.Name.length < 3,
            Urls: body.Urls.findIndex((url) => url.Url.length < 3),
          })
        );
      }
    },
    async deleteProject(project: ProjectItemType) {
      const formData = new FormData();
      formData.append("guid", project.ID as string | Blob);
      await fetch(`${process.env.VUE_APP_BASE_API_URL}/admin/project/remove`, {
        method: "POST",
        body: formData,
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else if (r.status === 401) {
            location.href = "/login";
          } else {
            throw new Error(r.statusText);
          }
        })
        .then((data) => {
          this.projectsList.splice(this.projectsList.indexOf(project), 1);
          this.isNotFound = this.projectsList.length === 0;
          return data;
        });
    },
    async toggleProject(
      project: ProjectItemType
    ): Promise<boolean | undefined> {
      const formData = new FormData();
      formData.append(
        "active",
        (!project.IsActive).toString() as string | Blob
      );
      return await fetch(
        `${process.env.VUE_APP_BASE_API_URL}/admin/project/toggle/${project.ID}`,
        {
          method: "POST",
          body: formData,
        }
      ).then((r) => {
        if (r.ok) {
          return r.ok;
        } else if (r.status === 401) {
          location.href = "/login";
        } else {
          throw new Error(r.statusText);
        }
      });
    },
    async deleteProjectUrl(
      model: ProjectModelType,
      index: number
    ): Promise<void> {
      if (typeof model.Urls[index].ID !== "undefined") {
        const formData = new FormData();
        setFormData(formData, {
          guid: model.Urls[index].ID,
        });
        await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/admin/project/url/remove`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then(async (r) => {
            const json = await r.json();
            if (r.ok) {
              return json;
            } else if (r.status === 401) {
              location.href = "/login";
            } else {
              throw new Error(json);
            }
          })
          .then(() => {
            model.Urls.splice(index, 1);
            this.projectsList
              ?.find((item: ProjectItemType) => item.ID === model.ID)
              ?.Urls.splice(index, 1);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        model.Urls.splice(index, 1);
      }
    },
  },
});
