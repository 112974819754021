import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AppLayout from "@/layout/AppLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        path: "/",
        name: "index",
        component: () => import("@/views/IndexView.vue"),
      },
      {
        path: "/create",
        name: "create",
        component: () => import("@/views/CreateEditView.vue"),
      },
      {
        path: "/:guid/edit",
        name: "edit",
        component: () => import("@/views/CreateEditView.vue"),
      },
      {
        path: "/projects",
        name: "projects",
        component: () => import("@/views/ProjectsView.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
