export function generateId(): string {
  return (Math.random() * 1e4).toString(16);
}
export const isDate = (obj: object) =>
  Object.prototype.toString.call(obj) === "[object Date]";
export function formatDateTime(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
}
export function getTimeFromDate(date: Date): string {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
}
export function setQueryParams<T>(params: T): string {
  const urlParams = new URLSearchParams();
  for (const item in params) {
    urlParams.set(item, String(params[item]));
  }
  return `?${urlParams.toString()}`;
}
export function setFormData(
  formData: FormData,
  obj: Record<string, unknown>,
  namespace = ""
): void {
  Object.entries(obj).forEach(([propertyName, propertyValue]) => {
    if (
      propertyName in obj &&
      propertyValue !== undefined &&
      propertyValue !== null &&
      propertyName[0] !== "_"
    ) {
      const formKey = namespace ? `${namespace}.${propertyName}` : propertyName;
      if (Array.isArray(propertyValue)) {
        propertyValue.forEach((element, index) => {
          if (typeof element !== "object") {
            formData.append(formKey, element);
          } else {
            const tempFormKey = `${formKey}.${index}`;
            setFormData(formData, element, tempFormKey);
          }
        });
      } else if (typeof propertyValue === "object") {
        setFormData(
          formData,
          propertyValue as Record<string, unknown>,
          formKey
        );
      } else {
        if (typeof propertyValue === "string") {
          if (propertyValue.length > 0) {
            formData.append(formKey, propertyValue as string | Blob);
          }
        } else {
          formData.append(formKey, propertyValue as string | Blob);
        }
      }
    }
  });
}
