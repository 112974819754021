<script setup lang="ts">
import { onBeforeMount } from "vue";
import { useProjectStore } from "@/store/projectStore";
import { usePopUpUserService } from "@/services/PopUpUserService";
import { useRouter } from "vue-router";
const projectStore = useProjectStore();
const fetchProjects = () => projectStore.fetchProjectsList();
const { fetchPopUpUser } = usePopUpUserService();
const router = useRouter();

onBeforeMount(() => {
  fetchPopUpUser()
    .then(() => fetchProjects())
    .catch(() => {
      router.push({ name: "login" });
    });
});
</script>

<template>
  <router-view />
</template>

<style lang="scss"></style>
